body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.topnav {
  background-color: #161616;
  display: flex;
  position: fixed;
  flex: 1 1;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.navBar {
  font-size: 24px;
}

.topHeader {
  color: #ffffff;
  font-family: main-font;
  font-size: 30px;
}

.topnav img {
  margin-left: 15px;
  margin-right: 30px;
  vertical-align: middle;
  height: 40px;
}

.topnav a {
  font-family: main-font;
  vertical-align: middle;
  color: #ffffff;
  padding: 15px 16px;
  text-decoration: none;
  font-size: 18px;
}

.topnav a:hover {
  color: #2196f3;
}

body {
  width: auto;
  background-color: #141414;
}

.middle-layout {
  padding-top: 120px;
  padding-bottom: 10px;
  background-color: #202020;
}

.products-layout {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #161616;
}

.products-layout2 {
  padding: 10px;
  background-color: #03C2B0;
}

.products-layout3 {
  padding: 10px;
  background-color: #448aff;
}

.m-header {
  color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  font-family: main-font;
  font-size: 30px;
}

.m-desc {
  color: #909090;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 50px;
  font-family: main-font;
  font-size: 20px;
}

.m-desc4 {
  color: #959595;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2px;
  font-family: main-font;
  font-size: 18px;
}

.m-header2 {
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin-left: 1%;
  margin-right: 3%;
  font-family: main-font;
  font-size: 32px;
}

.app-icon {
  vertical-align: middle;
  margin-left: 10%;
  align-items: m-header;
  height: 48px;
  width: 48px;
}

.contact-layout {
  padding-bottom: 40px;
}

.twitter-icon {
  vertical-align: middle;
  align-items: m-header;
  margin-left: 10%;
  height: 42px;
  width: 42px;
}

.fb-icon {
  vertical-align: middle;
  align-items: m-header;
  margin-left: 2%;
  height: 42px;
  width: 42px;
}

.nf-app-icon {
  vertical-align: middle;
  align-items: m-header;
  margin-left: 10%;
  height: 62px;
  width: 62px;
}

.m-desc2 {
  color: #909090;
  margin-left: 10%;
  margin-right: 10%;
  font-family: main-font;
  font-size: 20px;
}

.m-desc3 {
  color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  font-family: main-font;
  font-size: 20px;
}

.material-button-blue {
  box-sizing: border-box;
  display: block;
  margin-left: 10%;
  border: none;
  border-radius: 4px;
  width: 150px;
  padding: 0 16px;
  min-width: 64px;
  height: 36px;
  text-decoration: none;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  background-color: rgb(33, 150, 243);
  background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}

.material-button-dark {
  box-sizing: border-box;
  display: block;
  margin-left: 10%;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  min-width: 64px;
  width: 150px;
  height: 36px;
  text-decoration: none;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #141414;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
  font-family: var(--pure-material-font, "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system);
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}

.cb1 {
  height: 450px;
  margin-left:7%;
}

.topnav a.active {
  background-color: #2196f3;
  color: white;
}

@font-face {
  font-family: main-font;
  src: url(/static/media/Montserrat-Regular.ee653992.ttf);
}
